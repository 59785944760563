<template>
  <div>
    <navigation-bar />
    <div class="router-view">
      <router-view />
    </div>
    <site-footer />
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar';
import SiteFooter from '@/components/SiteFooter';

export default {

  components: {
    NavigationBar,
    SiteFooter,
  },

  mounted() {
    // Initially we want to load the projects and categories
    // So that they are available at all times
    this.$store.dispatch('getProjects');
    this.$store.dispatch('getCategories');
  },

};
</script>

<style>

</style>
