export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hallo kaas!"])},
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projecten"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachtgevers"])}
  },
  "clients": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet alleen zijn onze opdrachtgevers blij met ons, wij zijn ook heel blij met onze opdrachtgevers. De afgelopen jaren hebben we met veel plezier en succes samengewerkt met de volgende opdrachtgevers:"])},
    "arts-and-culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunst & cultuur"])},
    "education-and-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwijs & management"])},
    "telecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecommunicatie"])},
    "finance-and-insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiën & verzekeringen"])},
    "healthcare-and-wellness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezondheidszorg & Wellness"])},
    "architecture-and-engineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architectuur & techniek"])},
    "industry-and-consumer-goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie & consumentengoederen"])},
    "media-and-publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media & Uitgeverijen"])},
    "fashion-and-beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode & verzorging"])}
  },
  "footer": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projecten"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachtgevers"])}
  }
}