<template>
  <div>
    <div
      class="container"
      :class="$route.name === 'slider' ? 'index' : ''"
    >
      <!--FOOTER-->
      <footer class="footer">
        <hr>
        <div class="row">
          <div class="col-lg-4 sitemap">
            <ul>
              <li>
                <router-link to="/">
                  Visualspace
                </router-link>
              </li>
              <li>
                <router-link to="/#index">
                  {{ $t("footer.about") }}
                </router-link>
              </li>
              <li>
                <router-link to="/projects">
                  {{ $t("footer.projects") }}
                </router-link>
              </li>
              <li>
                <router-link to="/clients">
                  {{ $t("footer.clients") }}
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-8">
            <address>
              Entrepotdok 143<br>
              1018 AD Amsterdam<br>
              <br>
              <a
                href="mailto:info@visualspace.nl?subject=Please%20contact%20me"
              >
                info [at] visualspace [dot] nl
              </a>
            </address>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
