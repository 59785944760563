import { createApp } from 'vue';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/scss/custom.scss';
import 'bootstrap';
// import 'flickity';
// import 'flickity/dist/flickity.min.css';
import router from './router';
import i18n from './i18n';
import store from './store';

createApp(App).use(store).use(i18n).use(router)
  .mount('#app');
