<template>
  <div
    class="home"
  >
    <!-- <p>{{ $t("message") }}</p>
    <button class="btn btn-primary">
      Show Data
    </button> -->
    <!-- BACKGROUND VIDEO -->
    <div
      name="top"
      id="carouselExampleSlidesOnly"
      class="background-video"
    >
      <video
        playsinline="playsinline"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source
          :src="require('@/assets/video/vspace2.mp4')"
          type="video/mp4"
        >
      </video>
    </div>
    <!-- CAROUSEL -->
    <div
      id="homepagecarousel"
      class="vspace carousel carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <p class="d-block w-100 slide-text">
            Visualspace
          </p>
        </div>
        <div class="carousel-item">
          <p class="d-block w-100 slide-text">
            Media
          </p>
        </div>
        <div class="carousel-item">
          <p class="d-block w-100 slide-text">
            Design
          </p>
        </div>
        <div class="carousel-item">
          <p class="d-block w-100 slide-text">
            Technology
          </p>
        </div>
      </div>
    </div>
    <!-- /CAROUSEL -->

    <!-- ARROW DOWN-->
    <div class="container arrow-down d-flex align-items-end justify-content-center">
      <router-link
        to="/#index"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="#fff"
          class="bi bi-chevron-compact-down"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1.553 6.776a.5.5 0 0 1
              .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1
              .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
          />
        </svg>
      </router-link>
    </div>
    <!-- /ARROW DOWN-->

    <!-- ABOUT US-->
    <div
      class="container index"
      id="index"
    >
      <div v-if="$i18n.locale === 'en'">
        <div id="about-us">
          <h1>About Us</h1>
          <div class="about-us">
            <h3>Clear Solutions</h3>
            <p>
              We want to find clear solutions, which is why we combine strategy, visual design,
              and technology. The best solutions should be easy and stylish, even if it's sometimes
              challenging. If you're our customer, you can expect the best service, and we welcome
              your feedback. Our goal is for you and your customers to be satisfied
              with the&nbsp;outcome.
            </p>
            <p>
              Our communication with our clients is always transparent. Therefore, we explain
              in detail how we operate and provide a clear understanding of costs.
            </p>
            <h3>Main Activities</h3>
            <p>
              Our main focus is on developing and automating information streams. Our
              team of experts in graphic design, typography, design strategy, illustration,
              copywriting and editing, and 3D modeling enables us to cater to our clients'
              diverse&nbsp;needs.
            </p>
            <h3>This Site</h3>
            <p>
              The design of this website using Adobe XD and developed it with Vue and
              Bootstrap. We use Google Sheets and Google Cloud Storage as the backend,
              and Google Sheets serves as our CMS. To convert from CSV to JSON, we use
              Papaparse. We work in Visual Studio Code as our development&nbsp;environment.
            </p>
          </div>
        </div>
      </div>

      <div v-if="$i18n.locale === 'nl'">
        <div id="about-us">
          <h1>Over ons</h1>
          <div class="about-us">
            <h3>Eenvoudige oplossingen</h3>
            <p>
              Wij willen heldere oplossingen vinden, daarom combineren we strategie, visueel
              design en technologie. We vinden namelijk dat de beste oplossingen juist
              makkelijk en stijlvol moeten zijn, ook al is dat soms lastig. Als jij onze
              klant bent, dan kan je de beste service verwachten en we horen graag jouw
              feedback. Ons doel is dat jij en je klanten tevreden zijn met het&nbsp;resultaat.
            </p>
            <p>
              We proberen het contact met onze klanten transparant te houden, dus we leggen
              duidelijk uit hoe we werken en wat&nbsp;het kost.
            </p>
            <h3>Hoofdactiviteiten</h3>
            <p>
              Onze focus ligt op het ontwikkelen en automatiseren van informatie. We hebben ook een
              team van specialisten op het gebied van grafisch design, typografie, ontwerpstrategie,
              illustratie, copywriting en bewerking, en 3D-modellering. Daardoor kunnen we voldoen
              aan de verschillende wensen van onze&nbsp;klanten.
            </p>
            <h3>Deze site</h3>
            <p>
              Deze website hebben we ontworpen met Adobe XD en ontwikkeld met Vue en Bootstrap.
              Als backend gebruiken we Google Sheets en Google Cloud Storage, en Google Sheets
              is ons CMS. Om van CSV naar JSON te gaan gebruiken we Papaparse. We werken in
              Visual Studio Code als&nbsp;ontwikkelomgeving.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require('bootstrap');

const { Carousel } = bootstrap;

export default {
  name: 'Home',
  components: {
    // ...
  },

  data() {
    return {
      carousel: null,
    };
  },

  methods: {
    initCarousel() {
      const myCarousel = document.querySelector('#homepagecarousel');
      this.carousel = new Carousel(myCarousel, {
        interval: 2000,
      }).cycle();
    },
  },

  mounted() {
    this.initCarousel();
  },
};
</script>
