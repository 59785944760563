export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello cheese!"])},
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])}
  },
  "clients": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not only are we very happy with our clients, our clients are happy with us! We have succesfully worked with the following clients:"])},
    "arts-and-culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts & Culture"])},
    "education-and-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education & Management"])},
    "telecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecom"])},
    "finance-and-insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance & Insurance"])},
    "healthcare-and-wellness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare & Wellness"])},
    "architecture-and-engineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture & Engineering"])},
    "industry-and-consumer-goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry & Consumer Goods"])},
    "media-and-publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media & Publishing"])},
    "fashion-and-beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion & Beauty"])}
  },
  "footer": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])}
  }
}