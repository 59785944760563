import { createStore } from 'vuex';
import Papa from 'papaparse';

export default createStore({
  state: {
    projects: [],
    categories: [],
    project: {},
  },

  getters: {
    projects: (state) => state.projects,
    categories: (state) => state.categories,
    project: (state) => state.project,
  },

  mutations: {
    async setProjects(state, projects) {
      state.projects = projects;
    },
    async setCategories(state, categories) {
      state.categories = categories;
    },
    async setProject(state, project) {
      state.project = project;
    },
  },

  actions: {
    async getProjects({ commit }) {
      const api = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTbjMN3taQtSMlE08WGXlOL5MgH9Nuc0TQQRuqc7LXI0B6WI0wa7w0QR3gH-I144O9y4j50q68zegR8/pub?gid=0&single=true&output=csv';
      await Papa.parse(api, {
        download: true,
        quotes: true,
        escapeChar: '"',
        delimiter: ',',
        header: true,
        transform: (val) => {
          // This is to split fields into lists
          if (val.startsWith('*') && val.endsWith('*')) {
            return val.slice(1, -1).split(',');
          }
          return val;
        },
        complete: (results) => {
          commit('setProjects', results.data);
        },
      });
    },

    async getCategories({ commit }) {
      const api = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTbjMN3taQtSMlE08WGXlOL5MgH9Nuc0TQQRuqc7LXI0B6WI0wa7w0QR3gH-I144O9y4j50q68zegR8/pub?gid=1154225563&single=true&output=csv';
      await Papa.parse(api, {
        download: true,
        quotes: true,
        escapeChar: '"',
        delimiter: ',',
        header: true,
        transform: (val) => {
          // This is to split fields into lists
          if (val.startsWith('*') && val.endsWith('*')) {
            return val.slice(1, -1).split(',');
          }
          return val;
        },
        complete: (results) => {
          commit('setCategories', results.data);
        },
      });
    },
  },

  modules: {
  },

});
