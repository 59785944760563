<template>
  <div>
    <nav class="navbar fixed-top navbar-expand-xl navbar-dark bg-dark">
      <div class="container">
        <router-link
          v-if="$route.name != 'slider'"
          class="navbar-brand"
          to="/"
        >
          Visualspace
        </router-link>
        <a
          v-if="$route.name === 'slider'"
          class="navbar-brand"
          href="#top"
        >
          Visualspace
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/#index"
              >
                {{ $t("menu.about") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/projects"
              >
                {{ $t("menu.projects") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/clients"
              >
                {{ $t("menu.clients") }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav selectlanguage">
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="#"
                @click="switchLocale"
              >
                {{ displayLocale }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Collapse } from 'bootstrap';

export default {
  data() {
    return {
      collapsable: null,
    };
  },

  methods: {
    switchLocale() {
      if (this.$i18n.locale === 'nl') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'nl';
      }
    },
  },

  computed: {
    displayLocale() {
      // lang is the displayed choice
      let lang = 'NL';
      if (this.$i18n.locale === 'nl') {
        lang = 'EN';
      }
      return lang;
    },
  },

  mounted() {
    // Hide collapsed toggled menu on click on menu items
    // We have to do this because Bootstrap doesn't do this by default
    const element = document.querySelector('.navbar-collapse');
    this.collapsable = new Collapse(element, { toggle: false });
    const navLinks = document.querySelectorAll('.nav-link:not(.dropdown-toggle), .dropdown-item');

    navLinks.forEach((l) => {
      l.addEventListener('click', () => {
        if (window.matchMedia('(max-width: 992px)').matches) {
          this.collapsable.toggle();
        }
      });
    });
  },
};
</script>
