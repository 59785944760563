import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home';

const routes = [
  {
    path: '/',
    name: 'slider',
    component: Home,
  },
  {
    path: '/#index',
    name: 'about',
    component: Home,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects'),
  },
  {
    path: '/project/:slug',
    name: 'ProjectDetail',
    component: () => import(/* webpackChunkName: "project-detail" */ '../views/ProjectDetail'),
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/Clients'),
  },
];

const scrollBehavior = (to) => {
  if (to.hash) {
    // return {selector: to.hash}
    // Or for Vue 3:
    return { el: to.hash };
  }
  return { top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes,
});

export default router;
